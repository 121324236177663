<mat-list-item *ngIf="header">
  <mat-icon
    class="igo-chevron"
    mat-list-avatar
    igoCollapse
    [target]="filters"
    [collapsed]="filtersCollapsed"
    (click)="toggleFiltersCollapsed()"
    svgIcon="chevron-up" >
  </mat-icon>
  <h4 (click)="toggleLegendOnClick()" [ngStyle]="{'cursor': filtersCollapsed ? 'default' : 'pointer'}"  matLine>{{layer.title}}</h4>
  
  <button *ngIf="header"
    mat-icon-button
    [color]="layer.visible ? 'primary' : 'default'"
    collapsibleButton
    tooltip-position="below"
    matTooltipShowDelay="500"
    [matTooltip]="layer.visible ?
                  ('igo.geo.layer.hideLayer' | translate) :
                  ('igo.geo.layer.showLayer' | translate)"
    (click)="layer.visible = !layer.visible">
    <mat-icon
      [ngClass]="{disabled: !(inResolutionRange$ | async)}"
      [svgIcon]="layer.visible ? 'eye' : 'eye-off'">
    </mat-icon>
  </button>

</mat-list-item>

<div #filters class="igo-datasource-filters-container">
  <div #legend class="igo-layer-legend-container">
    <igo-layer-legend *ngIf="showLegend$ | async" [layer]="layer">
    </igo-layer-legend>
  </div>
  <igo-time-filter-form
    [layer]= "layer"
    [options]="datasource.options.timeFilter"
    [currentValue]="datasource.options.params.TIME"
    (change)="handleDateChange($event)"
    (yearChange)="handleYearChange($event)">
  </igo-time-filter-form>
</div>
