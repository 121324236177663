<mat-list *ngIf="mode === actionbarMode.Dock">

    <div *ngIf="heightCondition && positionConditionTop && isDesktop"
      id="topChevron">
      <button
        mat-icon-button
        tooltip-position="below"
        matTooltipShowDelay="500"
        [matTooltip]="'igo.common.actionbar.scrollUp' | translate"
        (click)="scrollUp()">
        <mat-icon svgIcon="chevron-up"></mat-icon>
      </button>
    </div>

    <igo-actionbar-item
      *ngIf="withToggleButton"
      color="accent"
      [withTitle]="false"
      [withIcon]="true"
      [color]="color"
      [disabled]="store.view.empty"
      [action]="toggleCollapseAction"
      (trigger)="onTriggerAction(toggleCollapseAction)">
    </igo-actionbar-item>

    <ng-template #buttonContent *ngIf="!collapsed" ngFor let-action [ngForOf]="store.view.all$() | async">
      <igo-actionbar-item
        color="accent"
        [withTitle]="withTitle"
        [withIcon]="withIcon"
        [withTooltip]="withTooltip"
        [color]="color"
        [disabled]="store.state.get(action).disabled"
        [action]="action"
        (trigger)="onTriggerAction(action)">
      </igo-actionbar-item>
    </ng-template>

    <div *ngIf="heightCondition && positionConditionLow && isDesktop"
      id="lowChevron">
      <button
        mat-icon-button
        tooltip-position="below"
        matTooltipShowDelay="500"
        [matTooltip]="'igo.common.actionbar.scrollDown' | translate"
        (click)="scrollDown()">
        <mat-icon svgIcon="chevron-down"></mat-icon>
      </button>
    </div>

</mat-list>

<div *ngIf="mode === actionbarMode.Overlay">
  <button class="buttonOverlay"
    mat-icon-button
    tooltip-position="below"
    matTooltipShowDelay="500"
    [matTooltip]="'igo.common.actionbar.icon' | translate"
    [matMenuTriggerFor]="actionbarMenu"
    [disabled]="store.view.empty"
    [color]="iconColor">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </button>

  <mat-menu
    #actionbarMenu="matMenu"
    class="igo-compact-menu igo-no-min-width-menu"
    overlapTrigger="true"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    [class]="overlayClass">

    <mat-list>
      <ng-template ngFor let-action [ngForOf]="store.view.all$() | async">
        <igo-actionbar-item
          color="accent"
          [withTitle]="withTitle"
          [withIcon]="withIcon"
          [color]="color"
          [action]="action"
          (trigger)="onTriggerAction(action)">
        </igo-actionbar-item>
      </ng-template>
    </mat-list>
  </mat-menu>
</div>
<mat-card *ngIf="mode === actionbarMode.Context" class="context-menu-card mat-elevation-z4">
  <mat-list>
      <ng-template ngFor let-action [ngForOf]="store.view.all$() | async">
          <igo-actionbar-item
            color="accent"
            [withTitle]="withTitle"
            [withIcon]="withIcon"
            [color]="color"
            [action]="action"
            (trigger)="onTriggerAction(action)">
          </igo-actionbar-item>
        <br/>
      </ng-template>
  </mat-list>
</mat-card>
