<mat-form-field>
  <input
    matInput
    [required]="required"
    [placeholder]="placeholder"
    [formControl]="formControl">
  <mat-icon
    *ngIf="disableSwitch === true"
    class="igo-form-disable-switch"
    [svgIcon]="(disabled$ | async) === true ? 'checkbox-blank-outline' : 'checkbox-marked-outline'"
    (click)="onDisableSwitchClick()"
    matPrefix>
  </mat-icon>
  <mat-error *ngIf="formControl.errors">{{getErrorMessage() | translate}}</mat-error>
</mat-form-field>
