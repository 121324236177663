<mat-form-field class="measure-field">
  <input
    matInput
    [readonly]="true"
    [placeholder]="placeholder"
    [value]="((measure$ | async) || 0) | measureFormat: measureUnit">
</mat-form-field>
<mat-form-field class="unit-field">
  <mat-select
    [value]="measureUnit"
    [disabled]="auto"
    (selectionChange)="onMeasureUnitChange($event.value)">
    <mat-option *ngFor="let measureUnit of measureUnits" [value]="measureUnit">
      {{('igo.geo.measure.' + measureUnit) | translate}}
    </mat-option>
  </mat-select>
</mat-form-field>
