
<form
  [autocomplete]="autocomplete"
  [formGroup]="form.control"
  (ngSubmit)="onSubmit()">
  <div class="igo-form-body" [ngClass]="{'igo-form-body-with-buttons': hasButtons}">
    <div class="igo-form-content">
      <ng-content></ng-content>
    </div>
    <div #buttons class="igo-form-buttons">
      <ng-content select="[formButtons]"></ng-content>
    </div>
  </div>
</form>
