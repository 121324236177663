<div class="igo-bookmark-button-container">
  <button
    mat-icon-button
    [matTooltip]="'igo.context.bookmarkButton.create' | translate"
    matTooltipPosition="above"
    [color]="color"
    (click)="createContext()">
    <mat-icon svgIcon="star"></mat-icon>
  </button>
</div>
