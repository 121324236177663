<div>
  <div class="measure-type-toggle mat-typography">
    <mat-button-toggle-group
      [value]="activeMeasureType"
      (change)="onMeasureTypeChange($event.value)">
      <mat-button-toggle [value]="measureType.Length">
        {{('igo.geo.measure.' + measureType.Length) | translate}}
      </mat-button-toggle>
      <mat-button-toggle [value]="measureType.Area">
        {{('igo.geo.measure.' + measureType.Area) | translate}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="measure-options mat-typography">
    <mat-slide-toggle
      [checked]="drawControlIsActive"
      [labelPosition]="'before'"
      (change)="onToggleDrawControl($event.checked)">
      {{'igo.geo.measure.toggleActive' | translate}}
    </mat-slide-toggle>

    <mat-slide-toggle
      [checked]="displayLines"
      [labelPosition]="'before'"
      (change)="onToggleDisplayLines($event.checked)">
      {{'igo.geo.measure.toggleDisplayLines' | translate}}
    </mat-slide-toggle> 

    <mat-slide-toggle
      [disabled]="!(hasArea$ | async)"
      [checked]="displayDistance"
      [labelPosition]="'before'"
      (change)="onToggleDisplayDistance($event.checked)">
      {{'igo.geo.measure.toggleDisplayDistance' | translate}}
    </mat-slide-toggle>

    <mat-slide-toggle
      [disabled]="!(hasArea$ | async)"
      [checked]="displayAreas"
      [labelPosition]="'before'"
      (change)="onToggleDisplayAreas($event.checked)">
      {{'igo.geo.measure.toggleDisplayAreas' | translate}}
    </mat-slide-toggle>

    <mat-slide-toggle
      [checked]="measureUnitsAuto"
      [labelPosition]="'before'"
      (change)="onToggleMeasureUnitsAuto($event.checked)">
      {{'igo.geo.measure.toggleAutoUnits' | translate}}
    </mat-slide-toggle>
  </div>

  <ng-container *ngIf="measure$ | async as measure">
    <igo-measurer-item
      [measureType]="measureType.Length"
      [measureUnit]="measureLengthUnit.Meters"
      [measure]="measure.length"
      [auto]="measureUnitsAuto"
      [placeholder]="(activeMeasureType === measureType.Area ? 'igo.geo.measure.perimeter' : 'igo.geo.measure.length') | translate"
      (measureUnitChange)="onLengthUnitChange($event)">
    </igo-measurer-item>

    <igo-measurer-item
      [measureType]="measureType.Area"
      [measureUnit]="measureAreaUnit.SquareMeters"
      [measure]="measure.area"
      [auto]="measureUnitsAuto"
      [placeholder]="'igo.geo.measure.area' | translate"
      (measureUnitChange)="onAreaUnitChange($event)">
    </igo-measurer-item>
  </ng-container>

  <div class="measure-store-buttons">
    <button
      mat-icon-button
      [matTooltip]="'igo.geo.measure.actionbar.calculate.tooltip' | translate"
      [disabled]="(selectedFeatures$ | async).length === 0"
      (click)="onCalculateClick()">
      <mat-icon svgIcon="calculator"></mat-icon>
    </button>

    <button
      mat-icon-button
      [matTooltip]="'igo.geo.measure.actionbar.delete.tooltip' | translate"
      [disabled]="(selectedFeatures$ | async).length === 0"
      (click)="onDeleteClick()">
      <mat-icon svgIcon="delete"></mat-icon>
    </button>

    <!--button
      mat-icon-button
      [matTooltip]="'igo.geo.measure.actionbar.modify.tooltip' | translate"
      [disabled]="(selectedFeatures$ | async).length !== 1"
      (click)="onModifyClick()">
      <mat-icon svgIcon="edit"></mat-icon>
    </button-->
  </div>

  <igo-entity-table
    #table
    class="table-compact"
    [store]="store"
    [template]="tableTemplate">
  </igo-entity-table>
</div>
