<igo-actionbar
  *ngIf="(toolbar$ | async).length > 0"
  [store]="actionStore"
  [withIcon]="true"
  [withTitle]="toolbarWithTitle$ | async"
  [withTooltip]="!(toolbarWithTitle$ | async)"
  [scrollActive]="toolbarWithTitle$ | async"
  [horizontal]="false">
</igo-actionbar>

<div
  *ngIf="activeTool$ | async as tool"
  class="igo-tool-container"
  [ngClass]="{'igo-tool-container-with-toolbar': !actionStore.empty, 'igo-tool-container-with-animation': animate}"
  [@toolSlideInOut]="animation$ | async"
  (@toolSlideInOut.start)="onAnimationStart()"
  (@toolSlideInOut.done)="onAnimationComplete()">

  <igo-dynamic-outlet
    [component]="tool.component"
    [inputs]="getToolInputs(tool)">
  </igo-dynamic-outlet>

</div>
