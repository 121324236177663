<button
(mouseenter)="onMouseEvent($event)" (mouseleave)="onMouseEvent($event)"
*ngIf="layer.meta.dataType === 'Layer'"
mat-icon-button
tooltip-position="below"
matTooltipShowDelay="500"
[matTooltip]="(tooltip$ | async) | translate"
[color]="(isPreview$ | async) ? '' : added ? 'warn' : ''"
(click)="onToggleClick($event)">
<mat-icon
  matBadge
  igoMatBadgeIcon="eye-off"
  igoMatBadgeInverseColor="true"
  [matBadgeHidden]="(inRange$ | async)"
  matBadgeDisabled="true"
  matBadgeSize="small"
  matBadgePosition="after"
  [svgIcon]="(isPreview$ | async) ? 'plus' : added ? 'delete' : 'plus'">
</mat-icon>
</button>
