<mat-tab-group *ngIf="hasApi" >
  <mat-tab [label]="'igo.context.shareMap.shareWithApi' | translate">
    <igo-share-map-api [map]="map"></igo-share-map-api>
  </mat-tab>
  <mat-tab [label]="'igo.context.shareMap.shareWithUrl' | translate">
    <igo-share-map-url [map]="map"></igo-share-map-url>
  </mat-tab>
</mat-tab-group>

<igo-share-map-url *ngIf="!hasApi" [map]="map"></igo-share-map-url>
