
<igo-form
  #igoForm
  [form]="form"
  [formData]="feature$ | async"
  (submitForm)="onSubmit($event)">

  <ng-content></ng-content>
  
  <ng-content select="[formButtons]" formButtons></ng-content>
  
</igo-form>
