import { NgModule } from '@angular/core';
import { IgoDrawModule } from './draw/draw.module';

@NgModule({
    imports: [

    ],
    declarations: [
    ],
    exports: [
        IgoDrawModule
    ]
})

export class IgoDrawingToolModule {}
