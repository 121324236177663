<form class="igo-form" [formGroup]="stopsForm">
  <!-- <div class="igo-form-button-group">

  </div> -->
  <div #inputs formArrayName="stops" *ngFor="let stop of stops.controls; let i = index;">
    <mat-list-item [formGroupName]="i">

      <div class="igo-input-container">
        <button *ngIf="map.geolocationFeature" mat-icon-button tooltip-position="below" matTooltipShowDelay="500" [matTooltip]="'igo.geo.directionsForm.geolocate' | translate" (click)="geolocateStop(i)">
          <mat-icon svgIcon="crosshairs-gps"></mat-icon>
        </button>
        <mat-form-field>
          <input matInput formControlName="stopPoint" [matAutocomplete]="auto"
            placeholder="{{'igo.geo.directionsForm.'+stop.value.directionsText | translate}}" (focus)="focus(i)"
            (keyup)="keyup(i,$event)" (keydown.enter)="prevent($event)">
            <button 
              mat-button 
              *ngIf="stop.value && (stop.value.stopPoint.length > 0 || stop.value.stopProposals.length > 0)"
              matSuffix mat-icon-button color="warn" aria-label="Clear" (click)="clearStop(i)">
              <mat-icon svgIcon="close"></mat-icon>
            </button>

          <mat-autocomplete #auto="matAutocomplete">
            <mat-optgroup *ngFor="let source of stop.value.stopProposals" [label]="source.source.title" [disabled]="source.source.enabled === false">
              <mat-option *ngFor="let result of source.results" [value]="result.meta ? result.meta.title : ''" 
              (onSelectionChange)="chooseProposal(result,i)"
              matTooltipShowDelay="500" [matTooltip]="result.meta ? result.meta.title : ''">
                {{ result.meta ? result.meta.title : '' }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>

        <!-- <div class="igo-form-button-group"> -->


          <!-- <button *ngIf="i !== 0" mat-icon-button tooltip-position="below" matTooltipShowDelay="500" [matTooltip]="'igo.geo.directionsForm.raiseStop' | translate"
            [color]="color" (click)="raiseStop(i)">
            <mat-icon svgIcon="arrow-upward"></mat-icon>
          </button>
          <button *ngIf="i !== stops.length - 1" mat-icon-button tooltip-position="below" matTooltipShowDelay="500" [matTooltip]="'igo.geo.directionsForm.lowerStop' | translate"
            [color]="color" (click)="lowerStop(i)">
            <mat-icon svgIcon="arrow-downward"></mat-icon>
          </button> -->

          <button *ngIf="i !== 0 && i !== stops.length - 1" mat-icon-button tooltip-position="below" matTooltipShowDelay="500" [matTooltip]="'igo.geo.directionsForm.removeStop' | translate"
            color="warn" (click)="removeStop(i)">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        <!-- </div> -->
      </div>

    </mat-list-item>
  </div>
  <div class="igo-form-button-group">
    <button mat-icon-button tooltip-position="below" matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.directionsForm.addStop' | translate" color="primary" (click)="addStop()">
      <mat-icon svgIcon="map-marker-plus"></mat-icon>
    </button>
    <button mat-icon-button tooltip-position="below" matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.directionsForm.zoomRoute' | translate" *ngIf="routesResults" color="primary"
      (click)="zoomRoute()">
      <mat-icon svgIcon="magnify-plus-outline"></mat-icon>
    </button>
    <button mat-icon-button tooltip-position="below" matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.directionsForm.resetDirectionsBtn' | translate" *ngIf="routesResults" color="warn"
      (click)="resetForm(true)">
      <mat-icon svgIcon="file-restore"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="routesResults" tooltip-position="below" matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.directionsForm.copy' | translate" color="primary" (click)="copyDirectionsToClipboard()">
      <mat-icon svgIcon="content-copy"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="routesResults" tooltip-position="below" matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.directionsForm.link' | translate" color="primary" (click)="copyLinkToClipboard()">
      <mat-icon svgIcon="link"></mat-icon>
    </button>
  </div>

</form>

<div class="igo-input-container" *ngIf="routesResults">
  <mat-form-field *ngIf="routesResults && routesResults.length > 1">
    <mat-select placeholder="{{'igo.geo.directionsForm.drivingOptions' | translate}}" (selectionChange)="changeRoute()" [(ngModel)]="activeRoute">
      <mat-option *ngFor="let pathDirections of routesResults; let cnt = index;" [value]="pathDirections">
        Option {{cnt + 1}} : {{formatDistance(pathDirections.distance)}} ({{formatDuration(pathDirections.duration)}})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-divider *ngIf="routesResults && routesResults.length === 0"></mat-divider>

  <mat-list>
    <h2 mat-header class="igo-route-title mat-typography">{{activeRoute.title}}</h2>
    <h2 mat-subheader>{{formatDistance(activeRoute.distance)}}, {{formatDuration(activeRoute.duration)}}</h2>

    <mat-list-item 
    class="igo-steps"
    (mouseenter)="showSegment(step)" 
    (click)="showSegment(step,true)" 
    *ngFor="let step of activeRoute.steps; let cnt = index;"
      igoListItem>
      <mat-icon [ngClass]="formatStep(step,cnt).cssClass" mat-list-icon svgIcon="{{formatStep(step,cnt).image}}"></mat-icon>

      <h4 mat-line>{{cnt +1}}. {{formatStep(step,cnt).instruction}}</h4>
      <h4 mat-line class="right">{{formatDistance(step.distance)}}</h4>
    </mat-list-item>

    <mat-divider></mat-divider>

  </mat-list>

</div>
