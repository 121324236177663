<div class="igo-search-bar-container" [ngClass]="{empty: empty$ | async}">
  <mat-form-field [floatLabel]="floatLabel" [appearance]="appearance">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input
      #input
      matInput
      autocomplete="off"
      [ngClass]="{'hasSearchIcon': searchIcon}"
      [disabled]="disabled$ | async"
      [placeholder]="placeholder ? placeholder : (placeholder$ | async) ? (placeholder$.value | translate) : undefined"
      [value]="term$ | async"
      (keyup)="onKeyup($event)"
      (touchend)="onKeyup($event)">
  </mat-form-field>

  <div class="search-bar-buttons">
    <button
      mat-icon-button
      [color]="color"
      *ngIf="searchIcon !== undefined">
      <mat-icon svgIcon="{{searchIcon}}"></mat-icon>
    </button>

    <button
      *ngIf="!(empty$ | async)"
      mat-icon-button
      [color]="color"
      (click)="onClearButtonClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <igo-search-selector
      *ngIf="searchSelector"
      [searchTypes]="searchTypes"
      [searchType]="searchType$ | async"
      (searchTypeChange)="onSearchTypeChange($event)">
    </igo-search-selector>

    <igo-search-settings
      *ngIf="searchSettings"
      [pointerSummaryEnabled]="pointerSummaryEnabled"
      (pointerSummaryStatus)="pointerSummaryStatus.emit($event)"
      [searchResultsGeometryEnabled]="searchResultsGeometryEnabled"
      (searchResultsGeometryStatus)="searchResultsGeometryStatus.emit($event)"
      (searchSourceChange)="onSearchSettingsChange()">
    </igo-search-settings>
  </div>
</div>
