<mat-form-field class="igo-entity-selector">
  <mat-select
    [disabled]="disabled"
    [value]="selected$ | async"
    [multiple]="multi"
    [placeholder]="placeholder"
    (selectionChange)="onSelectionChange($event)">
    <mat-option *ngIf="emptyText !== undefined && multi === false" [value]="emptyValue">{{emptyText}}</mat-option>
    <mat-option *ngIf="multi === true" [value]="multiSelectValue">{{multiText$ | async}}</mat-option>
    <ng-template ngFor let-record [ngForOf]="store.stateView.all$() | async">
      <mat-option [value]="record.entity">
        {{titleAccessor(record.entity)}}
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
