<mat-list-item>
  <mat-icon *ngIf="icon" mat-list-avatar svgIcon="{{showIcons ? icon : 'blank'}}"></mat-icon>

  <h4 matLine *ngIf="titleHtml" [innerHtml]="titleHtml" matTooltipShowDelay="500" [matTooltip]="tooltipHtml" matTooltipClass="search-result-tooltip"></h4>
  <h4 matLine *ngIf="!titleHtml" matTooltipShowDelay="500" [matTooltip]="title">{{title}}</h4>

  <button *ngIf="withZoomButton"
    igoStopPropagation
    mat-icon-button
    (click)="onZoomHandler()">
    <mat-icon svgIcon="magnify"></mat-icon>
  </button>

  <ng-content
    select=[igoSearchItemToolbar]>
  </ng-content>

</mat-list-item>
