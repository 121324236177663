<form [formGroup]="form" role="form" (ngSubmit)="loginUser(form.value)">
  <div>
    <mat-form-field class="full-width">
      <input matInput required placeholder="{{'igo.auth.user' | translate}}" formControlName="username">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <input matInput required type="password" placeholder="{{'igo.auth.password' | translate}}" formControlName="password">
    </mat-form-field>
  </div>

  <button mat-raised-button type="submit" [disabled]="!form.valid || loading">{{'igo.auth.login' | translate}}</button>
  <button *ngIf="allowAnonymous" mat-raised-button class="anonymous" type="button" [disabled]="loading" (click)="loginAnonymous()">
    {{'igo.auth.accessAnonymous' | translate }}
  </button>
  <div *ngIf="error">
    <br/>
    <font size="3" color="red">{{error}}</font>
  </div>
</form>
