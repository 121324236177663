<form class="igo-form" [formGroup]="form">
  <div class="igo-input-container">
    <mat-form-field>
      <input
        matInput
        formControlName="title"
        placeholder="{{'igo.geo.printForm.title' | translate}}">
    </mat-form-field>
  </div>
  <div class="igo-input-container">
    <mat-form-field>
      <input
        matInput
        formControlName="subtitle"
        placeholder="{{'igo.geo.printForm.subtitle' | translate}}">
    </mat-form-field>
  </div>
  <div class="igo-input-container">
    <mat-form-field>
      <input
        matInput
        formControlName="comment"
        placeholder="{{'igo.geo.printForm.comment' | translate}}">
    </mat-form-field>
  </div>

  <div class="igo-input-container">
    <div class="print-slide-toggle-container mat-typography">
      <mat-slide-toggle
        class="print-option"
        formControlName="showProjection"
        [labelPosition]="'before'">
        {{'igo.geo.printForm.showProjection' | translate}}
      </mat-slide-toggle>
      <mat-slide-toggle
        class="print-option"
        formControlName="showScale"
        [labelPosition]="'before'">
        {{'igo.geo.printForm.showScale' | translate}}
      </mat-slide-toggle>
      <mat-slide-toggle
        class="print-option"
        formControlName="doZipFile"
        [labelPosition]="'before'"
        [style.display]="isPrintService ? 'none' : ''">
        {{'igo.geo.printForm.doZipFile' | translate}}
      </mat-slide-toggle>
    </div>
  </div>

  <div class="igo-input-container">
    <mat-form-field>
      <mat-select
        formControlName="legendPosition"
        placeholder="{{'igo.geo.printForm.legendPosition' | translate}}">
        <mat-option *ngFor="let legendPosition of legendPositions | keyvalue " [value]="legendPosition.key">
            {{'igo.geo.printForm.legendPositions.' + legendPosition.value | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="igo-input-container">
    <mat-form-field>
      <mat-select (selectionChange)="toggleImageSaveProp()"
        formControlName="outputFormat"
        placeholder="{{'igo.geo.printForm.outputFormat' | translate}}">
        <mat-option *ngFor="let outputFormat of outputFormats | keyvalue " [value]="outputFormat.key">
            {{outputFormat.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="igo-input-container" [style.display]="isPrintService ? 'block' : 'none'">
    <mat-form-field>
      <mat-select
        formControlName="paperFormat"
        placeholder="{{'igo.geo.printForm.paperFormat' | translate}}">
        <mat-option *ngFor="let paperFormat of paperFormats | keyvalue " [value]="paperFormat.key">
          {{('igo.geo.printForm.paperFormats.' + paperFormat.value) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="igo-input-container" [style.display]="isPrintService ? 'none' : 'block'">
    <mat-form-field>
      <mat-select
        formControlName="imageFormat"
        placeholder="{{'igo.geo.printForm.imageFormat' | translate}}">
        <mat-option *ngFor="let imageFormat of imageFormats | keyvalue " [value]="imageFormat.key">
          {{imageFormat.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="igo-input-container" style="display: none;">
    <mat-form-field>
      <mat-select
        formControlName="resolution"
        placeholder="{{'igo.geo.printForm.resolution' | translate}}">
        <mat-option *ngFor="let resolution of resolutions | keyvalue " [value]="resolution.key">
          {{resolution.value + ' PPI'}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="igo-input-container" [style.display]="isPrintService ? 'block' : 'none'">
    <mat-form-field>
      <mat-select
        formControlName="orientation"
        placeholder="{{'igo.geo.printForm.orientation' | translate}}">
        <mat-option *ngFor="let orientation of orientations | keyvalue " [value]="orientation.key">
          {{('igo.geo.printForm.' + orientation.value) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="igo-form-button-group print-button-top-padding">
    <button
      mat-raised-button
      type="button"
      [disabled]="!form.valid || (disabled$ | async)"
      (click)="handleFormSubmit(form.value, form.valid)">
      {{'igo.geo.printForm.saveBtn' | translate}}
    </button>
  </div>

</form>
