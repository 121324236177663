import { NgModule } from '@angular/core';

import { IgoMeasurerModule } from './measurer/measurer.module';

@NgModule({
  imports: [],
  declarations: [],
  exports: [
    IgoMeasurerModule
  ]
})
export class IgoMeasureModule {}
