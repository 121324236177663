<div
  *ngIf="group && group.fields.length > 0"
  class="igo-form-group-fields">
  <div
    *ngFor="let field of group.fields"
    class="igo-form-field-wrapper"
    [ngClass]="getFieldNgClass(field)">
    <igo-form-field [field]="field"></igo-form-field>
  </div>
</div>

<div class="igo-form-group-extra-content">
  <ng-content></ng-content>
</div>

<mat-error *ngIf="formControl.errors">{{getErrorMessage() | translate}}</mat-error>
