<mat-list-item class= "igo-layer-list-item">
  <h4 matLine class="igo-layer-title" [matTooltip]="tooltipText" matTooltipShowDelay="500">{{layer.title}}</h4>
</mat-list-item>

<div #legend class="igo-layer-legend-container">
  <igo-layer-legend
    [layer]="layer"
    [updateLegendOnResolutionChange]="updateLegendOnResolutionChange">
  </igo-layer-legend>
</div>
