<div #flexibleMain class="igo-flexible-main {{state}} {{direction}}">
  <div class="igo-container">
    <ng-content></ng-content>
  </div>
</div>
<div class="igo-flexible-fill">
  <div>
  	<div class="igo-container">
      <ng-content select="[igoFlexibleFill]"></ng-content>
    </div>
  </div>
</div>
