<mat-list-item>
  <mat-icon
    mat-list-avatar
    svgIcon="chevron-up"
    igoCollapse
    class="igo-chevron"
    [target]="items"
    [collapsed]="collapsed"
    (toggle)="onToggleCollapsed($event)">
  </mat-icon>

  <h4 class="igo-catalog-group-title" id="catalog-group-title" mat-line matTooltipShowDelay="500" [matTooltip]="title" (click)="onTitleClick()">{{title}}</h4>
  <ng-container *ngIf="(added$ | async) && !(preview$ | async); else notadded">
    <button
      mat-icon-button
      tooltip-position="below"
      matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.catalog.group.removeFromMap' | translate"
      color="warn"
      [disabled]="disabled$ | async"
      (click)="onToggleClick()">
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
  </ng-container>

  <ng-template #notadded>
    <button
      mat-icon-button
      tooltip-position="below"
      matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.catalog.group.addToMap' | translate"
      [disabled]="disabled$ | async"
      (click)="onToggleClick()">
      <mat-icon svgIcon="plus"></mat-icon>
    </button>
  </ng-template>
</mat-list-item>

<div #items>
  <ng-template ngFor let-item [ngForOf]="store.view.all$() | async">
    <ng-container *ngIf="isGroup(item)">
      <!-- todo: add display ans manage CatalogItemGroup -->
    </ng-container>
    <ng-container *ngIf="isLayer(item)">
      <igo-catalog-browser-layer
        igoListItem
        [layer]="item"
        [resolution]="resolution"
        [catalogAllowLegend]="catalogAllowLegend"
        [added]="state.get(item).added"
        (addedLayerIsPreview)="onLayerPreview($event)"
        (addedChange)="onLayerAddedChange($event)">
      </igo-catalog-browser-layer>
    </ng-container>
  </ng-template>
</div>
