<form class="form-list">
    <mat-form-field class="zone-list">
        <input #input type="text" placeholder="{{'igo.geo.spatialFilter.listLabel' | translate}}" matInput
        [formControl]="formControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onZoneChange($event.option.value)"
        [displayWith]="displayFn">
            <mat-option *ngFor="let entities of this.store.view.all$() | async" [value]="entities">
                {{entities.properties.nom}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
<form>

<div class="buffer-div">
    <form class="buffer-form">
        <mat-form-field class="buffer">
            <input type="number" matInput placeholder="{{'igo.geo.spatialFilter.buffer' | translate}}" [formControl]="bufferFormControl"
            [value]="0" [readonly]="!zone">
        </mat-form-field>
    </form>

    <mat-form-field class="unit-field">
        <mat-select
        [value]="measureUnit"
        (selectionChange)="onMeasureUnitChange($event.value)">
        <mat-option *ngFor="let measureUnit of measureUnits" [value]="measureUnit">
            {{('igo.geo.measure.' + measureUnit) | translate}}
        </mat-option>
        </mat-select>
    </mat-form-field>
</div>
