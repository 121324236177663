<div *ngIf="withHeader" class="igo-panel-header mat-typography" title="">
  <h3>
    <ng-content select="[panelLeftButton]"></ng-content>
    <div class="igo-panel-title">
      {{ title }}
      <ng-content select="[panelHeader]"></ng-content>
    </div>
    <ng-content select="[panelRightButton]"></ng-content>
  </h3>
</div>
<div class="igo-panel-content" title="">
  <ng-content></ng-content>
</div>
