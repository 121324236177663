<h1 mat-dialog-title class="mat-typography">{{ title }}</h1>

<div mat-dialog-content class="mat-typography">
  <ng-container *ngTemplateOutlet="loopObject;context:{ obj: data }"></ng-container>

  <ng-template #loopObject let-obj='obj' let-baseKey='baseKey'>
    <ng-container *ngFor="let property of obj | keyvalue">
      <ng-container *ngIf="ignoreKeys.indexOf(getKey(baseKey, property.key)) === -1">

        <ng-container *ngIf="isObject(property.value); else notObject">
          <ng-container *ngTemplateOutlet="loopObject;context:{ obj: property.value, baseKey: getKey(baseKey, property.key) }"></ng-container>
        </ng-container>

        <ng-template #notObject>
          <p><span><b>{{getKey(baseKey, property.key)}}</b> : </span><span class="propertyValue" [innerHtml]="property.value"></span></p>
        </ng-template>

      </ng-container>
    </ng-container>
  </ng-template>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary"
          (click)="dialogRef.close(false)">
    OK
  </button>
</div>
