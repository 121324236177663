<button
  *ngIf="layerIsExportable()"
  mat-icon-button
  tooltip-position="below"
  matTooltipShowDelay="500"
  [matTooltip]="'igo.geo.download.action' | translate"
  [color]="color">
  <!-- (click)="openDownload(layer)"> -->
  <mat-icon svgIcon="file-export"></mat-icon>
</button>

