<h1 mat-dialog-title class="mat-typography">{{'igo.geo.catalog.library.addTitle' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <form class="igo-form" [formGroup]="form">
    <div class="igo-input-container">
      <mat-form-field>
        <input type="text" formControlName="url" placeholder="URL" matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='changeUrl($event.option.value)'>
          <mat-option *ngFor="let predefinedCatalog of (predefinedCatalogsList$ | async)" matTooltipShowDelay="500"
            [matTooltip]="predefinedCatalog.url" [value]="predefinedCatalog">
            {{predefinedCatalog.url}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="igo-input-container">
      <mat-form-field>
        <input
          matInput
          formControlName="title"
          placeholder="{{'igo.geo.printForm.title' | translate}}">
      </mat-form-field>
    </div>
    <div class="igo-input-container">
      <mat-form-field>
        <mat-select
          formControlName="type"
          placeholder="Type">
          <mat-option
            *ngFor="let type of typeCapabilities"
            [value]="type"
            (click)="$event.stopPropagation()">
              <p mat-line>{{type}}</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <span *ngIf="error && addedCatalog && emailAddress">
    <p class="error">{{languageService.translate.instant('igo.geo.catalog.externalProvider.unavailableWithEmail', { value: addedCatalog.url, email: emailAddress })}}</p>
  </span>
  <span *ngIf="error && addedCatalog && !emailAddress">
    <p class="error">{{languageService.translate.instant('igo.geo.catalog.unavailable', { value: addedCatalog.url })}}</p>
  </span>
</div>
<div mat-dialog-actions style="justify-content: center">
  <div class="igo-form-button-group add-catalog-button-top-padding">
    <button
      mat-raised-button
      type="button"
      (click)="cancel()">
      {{'igo.geo.catalog.library.cancel' | translate}}
    </button>
    <button
      id="addCatalogBtnDialog"
      mat-raised-button
      type="button"
      color="primary"
      [disabled]="!form.valid"
      (click)="addCatalog(form.value)">
      {{'igo.geo.catalog.library.add' | translate}}
    </button>
  </div>
</div>
