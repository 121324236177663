<h1 mat-dialog-title class="mat-typography">{{ 'igo.context.poiButton.dialog.title' | translate }}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput required autocomplete="off"
      [placeholder]="'igo.context.poiButton.dialog.placeholder' | translate"
      [(ngModel)]="title">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button color="primary"
         [disabled]="!title"
         (click)="dialogRef.close(title)">
    {{'igo.common.confirmDialog.confirmBtn' | translate}}
  </button>
  <button mat-button
          (click)="dialogRef.close(false)">
    {{'igo.common.confirmDialog.cancelBtn' | translate}}
  </button>
</div>
