<mat-list-item>
  <mat-icon
    svgIcon="chevron-up" 
    class="igo-chevron"
    mat-list-avatar
    igoCollapse
    [target]="content"
    [collapsed]="collapsed"
    (toggle)="collapsed = $event">
  </mat-icon>
  <h4 matLine>{{title}}</h4>
</mat-list-item>

<div #content>
  <ng-content></ng-content>
</div>
