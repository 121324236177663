<igo-print-form
  [outputFormat]="outputFormat"
  [paperFormat]="paperFormat"
  [orientation]="orientation"
  [imageFormat]="imageFormat"
  [resolution]="resolution"
  [legendPosition]="legendPosition"
  [disabled$]="disabled$"
  (submit)="handleFormSubmit($event)">
</igo-print-form>
