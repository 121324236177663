<mat-list-item>
  <mat-icon *ngIf="haveGroup()" mat-list-avatar svgIcon="blank"></mat-icon>
  <h4 mat-line matTooltipShowDelay="500" [ngClass]="(catalogAllowLegend)?'igo-cataloglayer-title':''" (click)="askForLegend($event)" [matTooltip]="computeTitleTooltip()">{{title}}</h4>

    <button *ngIf="layer.externalProvider"
      disabled="true"
      mat-icon-button>
    <mat-icon
      class="igo-cataloglayer-external-icon"
      *ngIf="layer.externalProvider"
      tooltip-position="below"
      matTooltipShowDelay="500"
      [matTooltip]="'igo.geo.catalog.externalProvider.layer' | translate"
      color="primary"
      (click)="$event.stopPropagation()"
      svgIcon="earth-arrow-right">
    </mat-icon>
  </button>
  <igo-metadata-button [layer]="layer"></igo-metadata-button>

  <button
    (mouseenter)="onMouseEvent($event)" (mouseleave)="onMouseEvent($event)"
    mat-icon-button
    tooltip-position="below"
    matTooltipShowDelay="500"
    [matTooltip]="computeTooltip() | translate"
    [color]="(isPreview$ | async) ? '' : added ? 'warn' : ''"
    (click)="onToggleClick($event)">
    <mat-icon
       matBadge
       igoMatBadgeIcon="eye-off"
       igoMatBadgeInverseColor="true"
       [matBadgeHidden]="isInResolutionsRange()"
       matBadgeDisabled="true"
       matBadgeSize="small"
       matBadgePosition="after"
       [svgIcon]="(isPreview$ | async) ? 'plus' : added ? 'delete' : 'plus'">
    </mat-icon>
  </button>

</mat-list-item>

<div #legend class="igo-cataloglayer-legend-container">
  <igo-layer-legend
    *ngIf="(layerLegendShown$ | async) && (igoLayer$ | async) && catalogAllowLegend"
    [layer]="igoLayer$ | async">
  </igo-layer-legend>
</div>
