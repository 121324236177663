<igo-geometry-form-field-input
  [formControl]="formControl"
  [map]="map"
  [geometryType]="geometryType$ | async"
  [drawGuide]="drawGuide$ | async"
  [measure]="measure"
  [drawControlIsActive]="drawControlIsActive"
  [freehandDrawIsActive]="freehandDrawIsActive"
  [controlOptions]="controlOptions"
  [drawStyle]="drawStyle"
  [overlayStyle]="overlayStyle">
</igo-geometry-form-field-input>

<div *ngIf="geometryTypeField" class="geometry-type-toggle">
  <mat-button-toggle-group
    [disabled]="(value$ | async) !== undefined"
    [(ngModel)]="geometryType">
    <mat-button-toggle
      value="Point"
      [disabled]="geometryTypes.indexOf('Point') < 0">
      {{'igo.geo.geometry.point' | translate}}
    </mat-button-toggle>
    <mat-button-toggle
      value="LineString"
      [disabled]="geometryTypes.indexOf('LineString') < 0">
      {{'igo.geo.geometry.line' | translate}}
    </mat-button-toggle>
    <mat-button-toggle
      value="Polygon"
      [disabled]="geometryTypes.indexOf('Polygon') < 0">
      {{'igo.geo.geometry.polygon' | translate}}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-form-field *ngIf="drawGuideField" class="draw-guide-field">
  <input
    matInput
    type="number"
    [placeholder]="drawGuidePlaceholder"
    [(ngModel)]="drawGuide">
  <mat-icon
    matPrefix
    [color]="'primary'"
    svgIcon="adjust">    
  </mat-icon>
  <span matSuffix class="draw-guide-units">{{'igo.geo.measure.meters' | translate}}</span>
</mat-form-field>